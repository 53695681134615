<template>
  <div class="scale-container">
    <div class="dashboard">
      <!-- 頂部導航 -->
      <header class="header">
        <div class="username">登入帳號：{{ username }}</div>
        <div class="search-bar">
          <img src="@/assets/image 6.png" alt="搜尋圖標" class="search-icon" />
          <input type="text" placeholder="請輸入客戶名稱" v-model="searchQuery" />
        </div>
        <button @click="handleLogout" class="logout-btn">登出</button>
      </header>

      <!-- 分隔線 -->
      <div class="header-divider"></div>

      <!-- 訂單卡片區域 -->
      <div class="orders">
              <div
        v-for="(order, index) in filteredOrders"
        :key="index"
        class="order-card"
        :class="{ urgent: order.urgent }"
      >
          <!-- 上方資訊區 -->
          <div class="order-top">
            <div class="order-info">
              <template v-if="order.urgent">
                <!-- 單一急件條件 -->
                <div class="urgent-wrapper">
                  <img src="@/assets/image 7.png" alt="急件" class="urgent-image" />
                </div>
                <span class="blackb">{{ order.deliveryDate }} 前完成</span>
              </template>
              <span class="bold">下單：{{ order.orderDate }}</span>
              <span class="bold">客戶：{{ order.customer }}</span>
            </div>
            <button class="edit-button" @click="goToEditPage(order.id, order.image)">
              <img src="@/assets/image 4.png" alt="修改圖標" class="edit-icon" /> 資料修改
            </button>
          </div>

          <!-- 加入底部線 -->
          <div class="divider"></div>

          <!-- 顯示已完成區域 -->
          <div v-if="order.completionTime" class="completed-container">
            <div class="completed-circle"></div>
            <div class="completion-time">完成時間：{{ order.completionTime }}</div>
          </div>

          <!-- 中間區域 -->
          <div class="order-middle">
            <!-- 左側圖片 -->
            <div class="image-section">
              <img
                :src="order.image"
                alt="商品圖片"
                class="product-image"
                @click="openModal(order.image)"
              />
            </div>

            <!-- 右側內容 -->
            <div class="details-section">
              <div class="right-column">
                <!-- 內部作業 -->
                <div class="workflow">
                  <h3 class="blackb">內部作業</h3>
                  <div class="workflow-buttons">
                    <button
                      v-for="(step, index) in order.steps"
                      :key="index"
                      :class="{ active: step.completed }"
                    >
                      {{ step.name }}
                    </button>
                  </div>
                </div>

                <!-- 廠商 -->
                <div class="vendors">
                  <h3 class="blackb">廠商</h3>
                  <div class="vendor-buttons">
                    <button
                      v-for="vendor in order.vendors"
                      :key="vendor.name"
                      :class="{ active: vendor.active }"
                    >
                      {{ vendor.name }}
                    </button>
                  </div>
                </div>

                <!-- 貨款與稅金 -->
                <div class="financials">
                  <div class="financials-item">
                    <span class="blackb">貨款金額</span>
                    <div class="amount">{{ order.amount }}</div>
                  </div>
                  <div class="financials-item">
                    <span class="blackb">稅金</span>
                    <div class="amount">{{ order.tax }}</div>
                  </div>
                  <div class="financials-item">
                    <span class="blackb">尾款</span>
                    <div class="amount">{{ order.balance }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 彈窗 -->
      <div
  v-if="isModalOpen"
  class="modal-overlay"
  :style="{ top: scrollY + 'px' }"
  @click="closeModal"
>
  <div class="modal-content" @click.stop>
    <img :src="currentImage" alt="完整圖片" class="modal-image" />
    <button class="close-button" @click="closeModal">關閉</button>
  </div>
</div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      username: this.$route.query.username || "未知用戶", // 從 Query 獲取參數
      orders: [], // 儲存從 Google Sheets 獲取的訂單資料
      searchQuery: "", // 搜索框輸入值
      scrollY: 0, // 用於記錄滾動位置
      isModalOpen: false, // 控制彈窗是否顯示
      currentImage: "", // 當前彈窗中顯示的圖片
    };
  },
  computed: {
    // 根據搜索框的輸入篩選訂單
    filteredOrders() {
      if (!this.searchQuery) return this.orders;
      return this.orders.filter((order) =>
        order.customer.includes(this.searchQuery)
      );
    },
  },
  mounted() {
    this.fetchData(); // 獲取數據
  },
  methods: {
    async fetchData() {
      try {
        const response = await axios.get("https://manshop.com.tw/api/dashboard-data");
        this.orders = response.data.data.map((order) => {
          // 確保圖片處理正確
          const fileIdMatch = order.image.match(/\/d\/(.+?)\//);
          if (fileIdMatch) {
            order.image = `https://drive.google.com/thumbnail?id=${fileIdMatch[1]}&sz=w2000`;
          }
          // 確保 urgent 是布林值
          order.urgent = !!order.urgent;
          return order;
        });
      } catch (error) {
        console.error("獲取 Dashboard 資料失敗", error);
      }
    },
    openModal(image) {
      // 判斷是否為手機或平板
      const userAgent = navigator.userAgent.toLowerCase();
      const isMobileOrTablet = /iphone|ipad|android|mobile|tablet/.test(userAgent);

      if (isMobileOrTablet) {
        console.log("手機或平板禁止彈窗");
        return; // 阻止彈窗邏輯
      }

      // 桌面設備允許彈窗
      this.scrollY = window.scrollY; // 記錄當前滾動位置
      this.currentImage = image;
      this.isModalOpen = true;
    },
    closeModal() {
    this.isModalOpen = false; // 關閉彈窗
    this.currentImage = ""; // 清空當前圖片
    },
    handleLogout() {
      this.$router.push("/"); // 返回登入頁面
    },
    goToEditPage(orderId, imageUrl) {
      this.$router.push({
        name: "EditPage", // 確保這裡的名稱與您的路由定義一致
        params: { id: orderId }, // 傳遞訂單 ID 作為路由參數
        query: { image: imageUrl, username: this.username }, // 傳遞圖片與登入帳號作為 query
      });
    },
  },
};
</script>

<style scoped>
.dashboard {
  font-family: Arial, sans-serif;
  padding: 20px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
  flex-wrap: wrap; /* 預設允許換行 */
  gap: 10px; /* 控制內部元素間的距離 */
}

.header .username {
  font-size: 16px;
  font-weight: bold;
}

.bold {
  color: #243dcb;
}

.blackb{
  color:black;
}

.amount{
  color:black;
}

.search-bar {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-grow: 1;
  max-width: 500px;
  margin: 10px 0;
  margin-left: 1200px;
}

.search-icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.search-bar input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.search-bar img {
  width: 20px;
  height: 20px;
}

.logout-btn {
  background-color: #add8e6;
  color: black;
  border: none;
  padding: 10px 30px;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 0;
}

.header-divider {
  height: 2px;
  background-color: #ccc;
  margin-bottom: 60px;
}

.orders {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  overflow-x: auto; /* 僅啟用水平滾動 */
}

.order-card {
  width: 100%;
  max-width: 1500px;
  border: 1px solid Gray;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-right: 20px white;
  background-color: white;
  padding: 10px;
  display: flex;
  white-space: nowrap; /* 禁止換行 */
  flex-direction: column;
  position: relative; /* 確保內部的灰色區域可以根據位置調整 */
}

.order-card.urgent {
  width: 100%;
  max-width: 1500px;
  border: 1px solid Gray;
  border-right: 20px solid red;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: relative; /* 確保內部的灰色區域可以根據位置調整 */
}

.order-top {
  display: flex;  /* 使用 flexbox 來讓所有元素在同一排顯示 */
  justify-content: space-between;  /* 讓元素兩端對齊 */
  align-items: center;  /* 保證所有元素在垂直方向上居中對齊 */
  margin-bottom: 10px;
  gap: 100px;  /* 控制元素之間的間距 */
  margin-top: -50px; /* 向上移動 */
}

.order-info {
  display: flex;
  flex-wrap: nowrap;  /* 防止換行 */
  justify-content: flex-start;  /* 使元素從左至右排列 */
  font-size: 20px;
  font-weight: bold;
  gap: 150px;  /* 每個元素之間的間距 */
  line-height: 10;  /* 調整行高，使文字垂直居中或稍微向下 */
}

.order-info span {
  display: inline-block; /* 確保每個項目都處於一行 */
}

.urgent-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-weight: bold;
  margin-right: 10px;
}

.divider {
  border-bottom: 2px solid black;
  margin-top: -60px; /* 向上移動 */
  margin-bottom: 20px; /* 可以保持原來的底部距離 */
}

.urgent {
  background-color: red;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-weight: bold;
}

.non-urgent {
  color: gray;
  font-weight: bold;
  font-size: 16px;
}

.order-middle {
  display: flex;
  flex-wrap: wrap; /* 允許內部元素換行 */
  gap: 20px; /* 增加內部元素間距 */
  width: 100%; /* 確保寬度可以擴展 */
}

.image-section {
  flex: 2;
  display: flex;
  align-items: flex-end;
}

.product-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 8px;
  cursor: pointer;
}

.details-section {
  flex: 3;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.workflow-buttons,
.vendor-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.workflow-buttons button,
.vendor {
  width: 70px;
  height: 70px;
  font-size: 20px;
  font-weight: bold;
  background-color: gray;
  color: white;
  border: none;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.workflow-buttons button.active,
.vendor.active {
  background-color: red;
}

.financials {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 50px;
}

.financials-item {
  flex: 1;
  min-width: 100px;
  text-align: center;
}

.financials-item .amount {
  border-top: 4px solid black;
  padding-top: 5px;
}

.financials-item span {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;  /* 添加間距，讓黑線與文字之間有空隙 */
}

.amount {
  border-top: 4px solid black;  /* 黑線放在數值上方 */
  padding-top: 3px;  /* 給數值與黑線之間添加間距 */
  width: 80%;  /* 設定黑線的長度，可以根據需要調整百分比 */
  margin: 0 auto;  /* 使黑線居中顯示 */
}

.edit-button {
  background-color: #a1e1a1;
  border: none;
  padding: 10px 30px;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
}

.edit-button img {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
    position: relative;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 增加陰影效果 */
    max-width: 90vw; /* 限制寬度 */
    max-height: 80vh; /* 限制高度 */
    overflow: auto; /* 若內容過多，顯示滾動條 */
}

.modal-image {
    width: 100%; /* 圖片占滿容器 */
    height: 100%; /* 保持比例 */
    max-width: 100%; /* 限制最大寬度 */
    max-height: 80vh; /* 限制最大高度 */
    border-radius: 8px; /* 圓角效果 */
}

.close-button {
    position: absolute; /* 固定在彈窗內部 */
    top: 10px;
    right: 10px;
    background-color: red;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
}

.urgent-image {
  width: 50%;
  height: 50%;
  margin-left: -40%;
  object-fit: contain;
  vertical-align: middle;
}

.edit-icon {
  width: 16px; /* 設定圖片寬度 */
  height: 16px; /* 設定圖片高度 */
  margin-right: 8px; /* 調整圖片和文字之間的間距 */
}

.completed-container {
  background-color: rgba(0, 0, 0, 0.5); /* 淡黑色背景並增加透明度 */
  padding: 20px;
  border-radius: 8px;
  position: absolute; /* 絕對定位 */
  top: calc(18% + 20px); /* 從divider下方開始，並保留一點空隙 */
  left: 0;
  right: 0;
  bottom: 0; /* 確保灰色區域延伸到order-card的底部 */
  z-index: 1; /* 確保灰色區域在所有元素之上 */
}

.completed-circle {
  position: relative;
  display: inline-block;
  background-image: url('@/assets/image 3.png'); /* 輸入您的圖片路徑 */
  background-size: cover; /* 使圖片覆蓋圓圈 */
  color: transparent; /* 讓文字不顯示 */
  padding: 30px 50px; /* 調整圓圈的大小 */
  border-radius: 50%; /* 保持圓形 */
  margin-bottom: 20px;
  width: 200px; /* 圓圈的寬度 */
  height: 200px; /* 圓圈的高度 */
  right: -600px;
  text-align: center;
}

.completion-time {
  font-size: 16px; /* 完成時間文字大小 */
  font-weight: bold;
  color: white; /* 文字顏色為白色 */
  text-align: center; /* 文字居中 */
  margin-top: -10px; /* 增加與圓圈的間距 */
  padding: 10px; /* 添加填充，增強排版效果 */
  background-color: rgba(0, 0, 0, 0.7); /* 背景色透明 */
  border-radius: 5px; /* 邊框圓角 */
}

/* 外層縮放容器樣式 */
.scale-container {
  width: 100%;
  height: 100%;
  overflow: auto; /* 啟用水平和垂直滾動 */
  transform-origin: top center; /* 設定縮放原點 */
}

.orders-container {
  display: flex; /* 子項目排成一行 */
  overflow-x: auto; /* 啟用水平滾動 */
  gap: 10px; /* 增加卡片之間的距離 */
  padding: 10px; /* 增加內邊距 */
}

.orders-container::-webkit-scrollbar {
  height: 8px; /* 滾動條高度 */
}

.orders-container::-webkit-scrollbar-thumb {
  background-color: #ccc; /* 滾動條顏色 */
  border-radius: 4px; /* 滾動條圓角 */
}

.vendor-buttons button {
  width: 70px;
  height: 70px;
  font-size: 20px;
  font-weight: bold;
  background-color: gray;
  color: white;
  border: none;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vendor-buttons button.active {
  background-color: red;
}

/* 預設不縮放（PC 螢幕） */
@media screen and (min-width: 1025px) {
  .scale-container {
    transform: scale(1); /* 正常大小 */
  }
  .order-info {
    display: flex;
    justify-content: space-between; /* 左右兩端對齊 */

  }
  .order-info span {
    font-size: 18px; /* 確保字體大小適合 PC */
    white-space: nowrap; /* 防止文字換行 */
  }
}

/* 平板橫式 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .scale-container {
    transform: scale(0.7); /* 縮放 90% */
  }
  .order-info {
    display: flex;
    justify-content: space-between;
    gap: 10px; /* 減少子元素間距 */
  }
  .order-info span {
    font-size: 12px; /* 調整字體大小 */
    white-space: nowrap; /* 防止文字換行 */
  }
  .divider{
    display: flex;
    margin-top: -3%; /* 調整數值以控制移動距離 */
  }

  .urgent-image {
  width: 100%; /* 調整寬度 */
  height: 100%; /* 調整高度 */
  }

  .header {
    display: flex; /* 使用 Flexbox 排版 */
    align-items: center; /* 垂直居中 */
    justify-content: space-between; /* 左右分佈 */
    gap: 10px; /* 元素間距 */
    flex-wrap: wrap; /* 允許換行（針對手機） */
    padding: 10px 20px; /* 添加內邊距 */
    box-sizing: border-box; /* 包括內邊距在寬度內 */
  }

  .username {
  flex-shrink: 0; /* 禁止縮小 */
  font-size: 16px; /* 預設字體大小 */
}
  .search-bar {
    max-width: 10%; /* 限制搜索欄的寬度 */
    margin-left: 10%;
  }
  .logout-btn {
  flex-shrink: 0; /* 禁止縮小 */
  background-color: #add8e6;
  color: black;
  border: none;
  padding: 8px 15px; /* 調整內邊距 */
  border-radius: 5px;
  font-size: 14px; /* 預設字體大小 */
  cursor: pointer;
}

  .image-section {
    flex-basis: 100%; /* 確保圖片區域的寬度佔據整行 */
    max-width: 100%; /* 避免被其他元素擠壓 */
    margin: 0 auto; /* 確保圖片在區域內居中 */
  }
  .product-image {
    width: 100%; /* 圖片的寬度與父容器一致 */
    height: 100%; /* 保持比例 */
    object-fit: contain; /* 防止圖片變形 */
    border-radius: 8px; /* 增加圓角效果 */
    display: block; /* 消除圖片多餘的空白間距 */
  }
  .orders {
    display: flex;
    flex-wrap: nowrap; /* 確保卡片橫向排列 */
    padding: 10px; /* 增加容器內間距，避免切邊 */
    gap: 10px; /* 卡片間距 */
    height: 100%; /* 確保高度限制 */
}
  .order-card {
  min-width: 95%; /* 設置卡片最小寬度，讓其更寬 */
  max-width: 90%; /* 限制卡片的寬度 */
  flex-shrink: 0; /* 禁止卡片縮小 */
  margin: 0 auto; /* 保證卡片在容器內居中 */
  }  

.order-card.urgent {
  min-width: 90%; /* 設置卡片最小寬度，讓其更寬 */
  max-width: 90%; /* 限制卡片的寬度 */
  flex-shrink: 0; /* 禁止卡片縮小 */
  margin: 0 auto; /* 保證卡片在容器內居中 */
}

  .completed-container {
  background-color: rgba(0, 0, 0, 0.5); /* 淡黑色背景並增加透明度 */
  padding: 20px;
  border-radius: 8px;
  position: absolute; /* 絕對定位 */
  top: calc(5% + 20px); /* 從divider下方開始，並保留一點空隙 */
  left: 0;
  right: 0;
  bottom: 0; /* 確保灰色區域延伸到order-card的底部 */
  z-index: 1; /* 確保灰色區域在所有元素之上 */
}

.completed-circle {
  position: relative;
  display: inline-block;
  background-image: url('@/assets/image 3.png'); /* 輸入您的圖片路徑 */
  background-size: cover; /* 使圖片覆蓋圓圈 */
  color: transparent; /* 讓文字不顯示 */
  padding: 30px 50px; /* 調整圓圈的大小 */
  border-radius: 50%; /* 保持圓形 */
  margin-bottom: 20px;
  width: 200px; /* 圓圈的寬度 */
  height: 200px; /* 圓圈的高度 */
  right: -200px;
  text-align: center;
}

.edit-button {
    background-color: #a1e1a1;
    border: none;
    padding: 8px 8px; /* 調整內邊距 */
    border-radius: 4px;
    font-size: 12px; /* 減小字體大小 */
    display: inline-flex; /* 使用 inline-flex 保證按鈕與其他元素在一行 */
    align-items: center;
    gap: 5px; /* 調整圖標與文字之間的間距 */
    margin-top: 5px; /* 向上移動 10px，根據需要調整值 */
    white-space: nowrap; /* 防止按鈕內的內容換行 */
}
  .urgent-wrapper {
    width: 10%;
    display: flex;
    align-items: center;
    gap: 1px; /* 急件圖標與文字間距 */
  }
  .urgent-wrapper img {
    width: 140%;
    margin-right: -80%;
    margin-top: 20%;
}

    .workflow-buttons {
    display: flex; /* 使用 flexbox 排列 */
    flex-wrap: wrap; /* 允許換行 */
    gap: 10px; /* 按鈕之間的間距 */
  }

  .order-header {
  display: flex; /* 使用 Flex 排版 */
  justify-content: space-between; /* 左右對齊 */
  align-items: center; /* 垂直方向居中 */
  gap: 10px; /* 控制間距 */
  flex-wrap: nowrap; /* 禁止換行 */
}
}

/* 手機橫式 */
@media screen and (min-width: 481px) and (max-width: 767px) {
  .scale-container {
    transform: scale(0.7); /* 縮放 90% */
  }
  .order-info {
    display: flex;
    justify-content: space-between;
    gap: 10px; /* 減少子元素間距 */
  }
  .order-info span {
    font-size: 12px; /* 調整字體大小 */
    white-space: nowrap; /* 防止文字換行 */
  }
  .divider{
    display: flex;
    margin-top: -3%; /* 調整數值以控制移動距離 */
  }
  .urgent-image {
  width: 100%; /* 調整寬度 */
  height: 100%; /* 調整高度 */
  }
  .header {
    display: flex; /* 使用 Flexbox 排版 */
    align-items: center; /* 垂直居中 */
    justify-content: space-between; /* 左右分佈 */
    gap: 10px; /* 元素間距 */
    flex-wrap: wrap; /* 允許換行（針對手機） */
    padding: 10px 20px; /* 添加內邊距 */
    box-sizing: border-box; /* 包括內邊距在寬度內 */
  }

  .username {
  flex-shrink: 0; /* 禁止縮小 */
  font-size: 16px; /* 預設字體大小 */
}
  .search-bar {
    max-width: 10%; /* 限制搜索欄的寬度 */
    margin-left: 10%;
  }
  .logout-btn {
  flex-shrink: 0; /* 禁止縮小 */
  background-color: #add8e6;
  color: black;
  border: none;
  padding: 8px 15px; /* 調整內邊距 */
  border-radius: 5px;
  font-size: 14px; /* 預設字體大小 */
  cursor: pointer;
}

  .image-section {
    flex-basis: 100%; /* 確保圖片區域的寬度佔據整行 */
    max-width: 100%; /* 避免被其他元素擠壓 */
    margin: 0 auto; /* 確保圖片在區域內居中 */
  }
  .product-image {
    width: 100%; /* 圖片的寬度與父容器一致 */
    height: 100%; /* 保持比例 */
    object-fit: contain; /* 防止圖片變形 */
    border-radius: 8px; /* 增加圓角效果 */
    display: block; /* 消除圖片多餘的空白間距 */
  }
  .orders {
    display: flex;
    flex-wrap: nowrap; /* 確保卡片橫向排列 */
    padding: 10px; /* 增加容器內間距，避免切邊 */
    gap: 10px; /* 卡片間距 */
    height: 100%; /* 確保高度限制 */
}
  .order-card {
  min-width: 95%; /* 設置卡片最小寬度，讓其更寬 */
  max-width: 90%; /* 限制卡片的寬度 */
  flex-shrink: 0; /* 禁止卡片縮小 */
  margin: 0 auto; /* 保證卡片在容器內居中 */
  }  

.order-card.urgent {
  min-width: 90%; /* 設置卡片最小寬度，讓其更寬 */
  max-width: 90%; /* 限制卡片的寬度 */
  flex-shrink: 0; /* 禁止卡片縮小 */
  margin: 0 auto; /* 保證卡片在容器內居中 */
}

  .completed-container {
  background-color: rgba(0, 0, 0, 0.5); /* 淡黑色背景並增加透明度 */
  padding: 20px;
  border-radius: 8px;
  position: absolute; /* 絕對定位 */
  top: calc(5% + 20px); /* 從divider下方開始，並保留一點空隙 */
  left: 0;
  right: 0;
  bottom: 0; /* 確保灰色區域延伸到order-card的底部 */
  z-index: 1; /* 確保灰色區域在所有元素之上 */
}

.completed-circle {
  position: relative;
  display: inline-block;
  background-image: url('@/assets/image 3.png'); /* 輸入您的圖片路徑 */
  background-size: cover; /* 使圖片覆蓋圓圈 */
  color: transparent; /* 讓文字不顯示 */
  padding: 30px 50px; /* 調整圓圈的大小 */
  border-radius: 50%; /* 保持圓形 */
  margin-bottom: 20px;
  width: 200px; /* 圓圈的寬度 */
  height: 200px; /* 圓圈的高度 */
  right: -200px;
  text-align: center;
}

.edit-button {
    background-color: #a1e1a1;
    border: none;
    padding: 8px 8px; /* 調整內邊距 */
    border-radius: 4px;
    font-size: 12px; /* 減小字體大小 */
    display: inline-flex; /* 使用 inline-flex 保證按鈕與其他元素在一行 */
    align-items: center;
    gap: 5px; /* 調整圖標與文字之間的間距 */
    margin-top: 5px; /* 向上移動 10px，根據需要調整值 */
    white-space: nowrap; /* 防止按鈕內的內容換行 */
}
.urgent-wrapper {
    width: 10%;
    display: flex;
    align-items: center;
    gap: 1px; /* 急件圖標與文字間距 */
    transform: translateX(-80px); /* 向左移動 20px，可根據需要調整 */
  }
  .urgent-wrapper img {
    width: 120%;
    height: auto;
    transform: translateX(100px);
    margin-top: 15%;
}

    .workflow-buttons {
    display: flex; /* 使用 flexbox 排列 */
    flex-wrap: wrap; /* 允許換行 */
    gap: 10px; /* 按鈕之間的間距 */
  }

  .order-header {
  display: flex; /* 使用 Flex 排版 */
  justify-content: space-between; /* 左右對齊 */
  align-items: center; /* 垂直方向居中 */
  gap: 10px; /* 控制間距 */
  flex-wrap: nowrap; /* 禁止換行 */
}
}

/* 手機直式 */
@media screen and (max-width: 480px) {
  .order-info {
    display: flex;
    justify-content: space-between;
    gap: 1px; /* 減少子元素間距 */
  }
  .order-info span {
    font-size: 12px; /* 調整字體大小 */
    white-space: nowrap; /* 防止文字換行 */
  }
  .divider {
  margin-top: 5%; /* 增加間距 */
  width: 100%; /* 確保寬度適配卡片 */
  border-bottom: 1px solid black; /* 增加清晰分隔 */
  }
  .urgent-image {
  width: 40px; /* 限制寬度 */
  height: auto; /* 自動調整高度 */
  margin-bottom: 10px; /* 增加下方間距 */
  }
  .header {
    flex-wrap: nowrap; /* 橫式模式不允許換行 */
  }

  .search-bar {
    max-width: 300px; /* 限制搜索欄的寬度 */
    margin-left: 10%;
  }
  .logout-btn {
    font-size: 14px; /* 調整按鈕文字大小 */
    padding: 8px 20px; /* 調整按鈕的間距 */
  }
    .workflow-buttons {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 每行顯示兩個按鈕 */
    gap: 10px; /* 按鈕間距 */
  }
  .edit-button {
    position: absolute; /* 使用絕對定位 */
    top: 10px; /* 與卡片頂部距離 */
    right: 10px; /* 與卡片右側對齊 */
    background-color: #a1e1a1;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    font-size: 14px; /* 調整字體大小 */
    z-index: 1; /* 保證按鈕在卡片上方 */
  }
  .orders {
    display: flex;
    flex-wrap: nowrap; /* 確保卡片橫向排列 */
    overflow-x: scroll; /* 開啟橫向滾動 */
    padding: 10px; /* 增加容器內間距，避免切邊 */
    gap: 10px; /* 卡片間距 */
  }
  
  .order-card {
    min-width: 600px; /* 設置卡片最小寬度，讓其更寬 */
    max-width: 600px; /* 限制卡片的寬度 */
    flex-shrink: 0; /* 禁止卡片縮小 */
    margin: 0 auto; /* 保證卡片在容器內居中 */
    padding: 20px; /* 增加內部間距 */
    box-sizing: border-box; /* 包括內邊距和邊框在寬度計算內 */
  }
  .image-section {
    flex-basis: 100%; /* 確保圖片區域的寬度佔據整行 */
    max-width: 100%; /* 避免被其他元素擠壓 */
    margin: 0 auto; /* 確保圖片在區域內居中 */
  }
  .product-image {
    width: 100%; /* 圖片的寬度與父容器一致 */
    height: 100%; /* 保持比例 */
    object-fit: contain; /* 防止圖片變形 */
    border-radius: 8px; /* 增加圓角效果 */
    display: block; /* 消除圖片多餘的空白間距 */
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 1024px) {
  .header{
    flex-wrap: nowrap;
  }

  .search-icon{
    margin-left: -540%;
  }

  .logout-btn{
    margin-left: -400%;
  }

  .order-card{
    width:88%;
  }

  .order-card.urgent {
    width:88%;
  }
  
  .edit-button {
  white-space: nowrap; /* 防止文字換行 */
  }

  .urgent-image {
    width: 60%;
    height: 60%;
  }
  
  .order-info{
    gap:50px;
  }
  
  .completed-circle{
    right:-400px;
  }
}
</style>
