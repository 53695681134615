<template>
  <div class="login-page">
    <div class="logo">
      <img src="@/assets/logoweb.png" alt="公司標誌" />
    </div>
    <div class="login-box">
      <h2>內部作業平台</h2>
      <hr />
      <p>請輸入您的帳號密碼</p>
      <form @submit.prevent="handleLogin">
        <div class="form-group">
          <label for="username">帳號：</label>
          <input
            id="username"
            type="text"
            placeholder="請輸入帳號"
            v-model="username"
          />
        </div>
        <div class="form-group">
          <label for="password">密碼：</label>
          <input
            id="password"
            type="password"
            placeholder="請輸入密碼"
            v-model="password"
          />
        </div>
        <input type="checkbox" v-model="rememberMe"/> 記住帳號
        <button type="submit" class="login-btn">登入</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      username: "",
      password: "",
      rememberMe: false, // 是否記住帳號
      error: null,
    };
  },
  methods: {
  // 加載記住的帳號
  loadRememberedAccount() {
    const savedUsername = localStorage.getItem("rememberedUsername");
    if (savedUsername) {
      this.username = savedUsername;
      this.rememberMe = true;
    }
  },
  // 登入處理
  async handleLogin() {
    if (this.rememberMe) {
      localStorage.setItem("rememberedUsername", this.username); // 記住帳號
    } else {
      localStorage.removeItem("rememberedUsername"); // 移除記住的帳號
    }

    try {
      const response = await axios.post("https://manshop.com.tw/api/login", {
        username: this.username,
        password: this.password,
      });

      if (response.data.success) {
        alert("登入成功！");
        // 傳遞用戶名到 Dashboard
        this.$router.push({ path: "/dashboard", query: { username: this.username } });
      } else {
        alert("帳號或密碼錯誤！");
      }
    } catch (err) {
      // 捕捉 HTTP 錯誤（如 401、500 等）
      console.error("登入錯誤", err);
      const errorMessage = err.response?.data?.message || "伺服器發生錯誤！";
      alert(errorMessage); // 顯示錯誤訊息
    }
  },
},
  mounted() {
    this.loadRememberedAccount(); // 在組件掛載時加載記住的帳號
  },
};
</script>

<style scoped>
/* 基本設置 */
.login-page {
  font-family: Arial, sans-serif;
  text-align: center;
  padding: 20px;
  background-color: #f8f8f8;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* 公司標誌區域 */
.logo img {
  width: 300px; /* 預設寬度 */
  max-width: 100%;
  height: auto; /* 保持比例 */
  margin-bottom: 20px;
}

/* 登入表單區域 */
.login-box {
  background-color: white;
  padding: 20px 40px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.login-box h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.login-box hr {
  border: none;
  border-top: 2px solid #000;
  margin: 10px auto;
  width: 100%;
}

.login-box p {
  font-size: 14px;
  margin-bottom: 20px;
  color: #555;
}

/* 表單組件 */
.form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;
}

.form-group label {
  font-size: 14px;
  display: block;
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* 登入按鈕 */
.login-btn {
  width: 100%;
  padding: 10px;
  background-color: #f8f8f8;
  border: 1px solid #000;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.login-btn:hover {
  background-color: #e0e0e0;
}

/* 複選框 */
input[type="checkbox"] {
  margin-right: 5px;
  margin-bottom: 10px;
  accent-color: #000;
}

/* 響應式設計 */

/* PC 橫向（大螢幕） */
@media screen and (min-width: 1025px) {
  .login-page {
    padding: 50px;
  }
  .logo img {
    width: 400px; /* 放大 Logo */
  }
  .login-box {
    max-width: 500px;
  }
  .login-box h2 {
    font-size: 28px;
  }
  .login-btn {
    font-size: 14px;
    padding: 8px;
    color: black; /* 將字體顏色設置為黑色 */
}
}

/* 平板橫式 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .login-page {
    padding: 40px;
  }
  .logo img {
    width: 350px;
  }
  .login-box {
    max-width: 450px;
  }
  .login-btn {
    font-size: 14px;
    padding: 8px;
    color: black; /* 將字體顏色設置為黑色 */
}
}

/* 手機橫式 */
@media screen and (min-width: 481px) and (max-width: 767px) {
  .login-page {
    padding: 30px;
  }
  .logo img {
    width: 300px;
  }
  .login-box {
    max-width: 400px;
    padding: 15px 30px;
  }
  .form-group input {
    padding: 6px;
  }
  .login-btn {
    font-size: 14px;
    padding: 8px;
    color: black; /* 將字體顏色設置為黑色 */
}
}

/* 手機直式 */
@media screen and (max-width: 480px) {
  .login-page {
    padding: 20px;
  }
  .logo img {
    width: 250px;
  }
  .login-box {
    max-width: 100%;
    padding: 15px;
  }
  .form-group input {
    padding: 5px;
    font-size: 12px;
  }
  .login-btn {
    font-size: 14px;
    padding: 8px;
    color: black; /* 將字體顏色設置為黑色 */
}
}
</style>