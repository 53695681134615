<template>
  <div class="edit-page">
    <!-- 頂部導航區域 -->
    <header class="header">
      <button @click="goBack" class="back-button">回首頁</button>
      <h1>資料修改 - 訂單 {{ orderId }}</h1>
      <button @click="handleLogout" class="logout-button">登出</button>
    </header>
    <div class="divider"></div>

    <!-- 主內容區域 -->
    <div class="main-content" v-if="!loading">
      <!-- 左邊圖片 -->
      <div class="image-container">
        <img :src="orderImage" alt="訂單圖片" class="order-image" />
      </div>

      <!-- 右邊流程 -->
      <div class="workflow-container">
        <div class="workflow-buttons">
          <button
            v-for="(item, index) in workflowSteps"
            :key="index"
            :class="['workflow-button', { completed: item.completed }]"
            @click="openForm(index)"
          >
            <span v-if="item.completed" class="circle"></span>
            {{ item.name }}
          </button>
        </div>
        <div class="workflow-description">
          <p>製作說明：紅色圓圈代表，狀態已完成</p>
        </div>
      </div>
    </div>

    <!-- 加載中提示 -->
    <div v-else class="loading">
      加載中...
    </div>

    <!-- 歷史更新記錄 -->
    <div class="history-section">
      <h2>歷史更新記錄</h2>
      <table>
        <thead>
          <tr>
            <th>時間戳記</th>
            <th>項目名稱</th>
            <th>處理人員</th>
            <th>回報事項</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(log, index) in historyLogs" :key="index">
            <td>{{ log.timestamp }}</td>
            <td>{{ log.item }}</td>
            <td>{{ log.user }}</td>
            <td>{{ log.feedback }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- 填寫資料的彈窗 -->
    <div v-if="isModalOpen" class="modal-overlay">
      <div class="modal-content">
        <h3>填寫資料</h3>
        <form @submit.prevent="handleSubmit">
          <div>
            <label>案件編號</label>
            <input type="text" :value="orderId" readonly />
          </div>
          <div>
            <label>客戶名稱</label>
            <input type="text" :value="customerName" readonly />
          </div>
          <div>
            <label>處理人員</label>
            <input type="text" v-model="formData.user" placeholder="輸入人名" required />
          </div>
          <div>
            <label>回報事項</label>
            <textarea v-model="formData.feedback" placeholder="輸入文字" required></textarea>
          </div>
          <div>
            <label>是否完成</label>
            <select v-model="formData.completed" required>
              <option disabled value="">請選擇</option>
              <option value="true">已完成</option>
              <option value="false">未完成</option>
            </select>
          </div>
          <div class="form-actions">
            <button type="submit" class="submit-btn">提交</button>
            <button type="button" class="cancel-btn" @click="closeModal">取消</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      orderId: this.id,
      orderImage: this.image || "/path/to/default-image.jpg",
      workflowSteps: [],
      historyLogs: [],
      loading: true,
      isModalOpen: false,
      currentStepIndex: null,
      customerName: "",
      formData: {
        user: "",
        feedback: "",
        completed: "",
      },
    };
  },
  mounted() {
    const username = this.$route.query.username || "未知用戶";
    this.formData.user = username; // 自動填入處理人員欄位
    this.fetchWorkflowSteps();
    this.fetchCompletedRecords();
  },
  methods: {
    goBack() {
      this.$router.push({
        name: "Dashboard", // 確保名稱正確
        query: { username: this.$route.query.username }, // 傳遞登入帳號
      });
    },
    handleLogout() {
      this.$router.push("/");
    },
    async fetchWorkflowSteps() {
  try {
    const response = await axios.get(
      `https://manshop.com.tw/api/get-workflow-steps/${this.orderId}`
    );
    if (response.data.success) {
      this.workflowSteps = response.data.workflowSteps; // 動態步驟名稱
      this.customerName = response.data.customerName || "未知客戶"; // 設置客戶名稱

      // 處理圖片連結
      const fileIdMatch = response.data.orderImage.match(/\/d\/(.+?)\//);
      if (fileIdMatch) {
        this.orderImage = `https://drive.google.com/thumbnail?id=${fileIdMatch[1]}&sz=w2000`;
      } else {
        this.orderImage = "/path/to/default-image.jpg"; // 如果無法匹配，設置預設圖片
      }
    } else {
      alert("無法獲取步驟數據：" + response.data.message);
    }
  } catch (error) {
    console.error("加載步驟數據時出錯：", error);
    alert("加載步驟數據時出錯：" + error.message);
  } finally {
    this.loading = false;
  }
},
    async fetchCompletedRecords() {
      try {
        const response = await axios.get(
          `https://manshop.com.tw/api/get-completed-records/${this.orderId}`
        );
        if (response.data.success) {
          this.historyLogs = response.data.historyLogs || [];
        } else {
          console.error("伺服器回應錯誤訊息：", response.data.message);
        }
      } catch (error) {
        console.error("加載完成記錄時出錯：", error.message);
        alert("加載完成記錄時出錯：" + error.message);
      }
    },
    openForm(index) {
      this.currentStepIndex = index;
      this.isModalOpen = true;
      this.formData.user = this.$route.query.username || "未知用戶"; // 自動代入用戶名
    },
    closeModal() {
      this.isModalOpen = false;
      this.formData = { user: "", feedback: "", completed: "" };
    },
    async handleSubmit() {
      const currentStep = this.workflowSteps[this.currentStepIndex];
      const isCompleted = this.formData.completed === "true";
      const payload = {
        orderId: this.orderId,
        stepName: currentStep.name,
        completed: isCompleted,
        user: this.formData.user,
        feedback: this.formData.feedback,
        isfinish: isCompleted ? "已完成" : "未完成",
      };

      try {
        const response = await axios.post(
          "https://manshop.com.tw/api/update-workflow-step",
          payload
        );
        if (response.data.success) {
          currentStep.completed = payload.completed;
          this.historyLogs.unshift({
            timestamp: new Date().toLocaleString(),
            item: currentStep.name,
            user: payload.user,
            feedback: payload.feedback,
            isfinish: payload.isfinish,
          });

          alert("提交成功！");
          this.closeModal();
          await this.fetchCompletedRecords();
        } else {
          alert("提交失敗：" + response.data.message);
        }
      } catch (error) {
        console.error("提交過程中發生錯誤：", error);
        alert("提交過程中發生錯誤：" + error.message);
      }
    },
  },
};
</script>
  
  
<style scoped>
.edit-page {
  font-family: Arial, sans-serif;
  max-width: 1600px; /* 設置最大寬度 */
  margin: 0 auto; /* 居中 */
  padding: 1rem; /* 增加邊距 */
}

.header {
  display: flex;
  flex-wrap: wrap; /* 小屏幕下換行 */
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
}


.divider {
  height: 2px;
  background-color: #ddd;
  margin: 20px 0;
}

.back-button,
.logout-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.back-button {
  background-color: #d9d9d9;
}

.logout-button {
  background-color: #add8e6;
}

.main-content {
  display: flex;
  justify-content: space-between; /* 左右分佈 */
  align-items: center; /* 垂直置中 */
  gap: 20px; /* 左右留空距 */
  flex-wrap: wrap; /* 允許內容換行 */
}

.image-section {
  flex: 1;
}

.order-image {
  max-width: 100%; /* 圖片寬度適配容器 */
  height: auto; /* 保持圖片比例 */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.image-container {
  flex: 1; /* 占據父容器的比例 */
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  max-width: 100%; /* 確保圖片不超出容器 */
}

.workflow-section {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.workflow-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.workflow-buttons {
  display: grid; /* 使用網格布局 */
  grid-template-columns: repeat(5, 1fr); /* 每排 6 個按鈕 */
  gap: 10px; /* 按鈕之間的間距 */
  justify-content: start; /* 從左對齊 */
  align-items: start; /* 垂直方向頂對齊 */
  margin: 20px 0; /* 上下留空 */
}

.workflow-button {
  position: relative;
  max-width: 140px; /* 設置按鈕最大寬度 */
  background-color: #ffffcc;
  font-size: 18px; /* 調整字體大小 */
  border: 2px solid #8b4513; /* 按鈕的邊框顏色 */
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  overflow: hidden; /* 確保內容不超出按鈕邊界 */
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  width: 140px; /* 固定寬度 */
  height: 80px; /* 固定高度 */
  line-height: 1.2; /* 調整文字行高 */
  padding: 10px; /* 增加內部間距，防止文字緊貼邊框 */
  word-break: break-word; /* 允許長文字換行 */
  white-space: normal; /* 避免文字不換行 */
}

.workflow-button.completed {
  background-color: #ffffcc; /* 保持按鈕背景為黃色 */
  color: #000; /* 文字顏色為黑色 */
}

.workflow-description {
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
  color: #555;
}

.history-section {
  margin-top: 30px;
}

.history-section h2 {
  font-size: 18px;
  font-weight: bold;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f4f4f4;
}

.modal-overlay {
  position: fixed; /* 確保彈窗覆蓋整個視窗 */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* 黑色半透明背景 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* 高於其他元素 */
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 添加陰影 */
  width: 40%;
  z-index: 1100; /* 必須高於 modal-overlay 的內容 */
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

input,
textarea,
select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

textarea {
  resize: none;
}

.form-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.submit-btn {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.cancel-btn {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.circle {
  position: absolute; /* 絕對定位 */
  top: 50%; /* 垂直居中 */
  left: 50%; /* 水平居中 */
  transform: translate(-50%, -50%); /* 將圓心調整到按鈕中心 */
  width: 30px; /* 圓圈大小 */
  height: 30px; /* 圓圈大小 */
  border: 5px solid #f5222d; /* 圓圈的邊框顏色和粗細 */
  border-radius: 50%; /* 確保圓形 */
  background-color: transparent; /* 圓圈內部透明 */
  z-index: 1; /* 保證圓圈覆蓋在文字上 */
}

/*直*/
@media screen and (max-width: 480px) {

  .header{
    width: 100%;
  }
  .main-content {
    flex-direction: column; /* 小螢幕時內容垂直排列 */
  }

  .image-container {
    width: 100%; /* 手機上圖片容器占滿整個屏幕 */
    height: 100%;
  }

  .order-image {
    width: 100%; /* 圖片寬度占滿容器 */
    max-height: 400px; /* 限制圖片高度以適應屏幕 */
  }

  .workflow-button {
    grid-template-columns: repeat(5, 1fr); /* 每排 6 個按鈕 */
    color: black !important; /* 確保文字保持黑色 */
  }

  th,
  td {
    color: black !important; /* 確保文字保持黑色 */
  }

  .modal-content {
    width: 80%; /* 在手機上寬度設置為屏幕的 90% */
    max-width: 80%; /* 保持屏幕邊距 */
    max-height: 80vh; /* 避免超出屏幕高度 */
    padding: 15px; /* 減少填充 */
  }
}

/*平板橫*/
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .main-content {
    flex-direction: row; /* 橫屏時回到橫向排列 */
    align-items: flex-start; /* 頂對齊 */
  }

  .image-container {
  width: auto;
  min-width: 300px; /* 設置最小寬度 */
}

.order-image {
  width: 100%; /* 讓圖片適應容器 */
  height: auto; /* 保持比例 */
}

      .workflow-button {
      grid-template-columns: repeat(5, 1fr); /* 每排 6 個按鈕 */
      color: black !important; /* 固定文字顏色為黑色 */
      background-color: #ffffcc; /* 保持背景顏色一致 */
      width: 100%; /* 讓按鈕隨容器自適應寬度 */
}

    th, td {
      color: black !important; /* 表格文字顏色固定為黑色 */
    }

    h1, h2, label, p {
      color: black !important; /* 所有標題、標籤和段落文字固定為黑色 */
    }

    .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 添加陰影 */
    width: 50%; /* 調整為屏幕寬度的 90% */
    max-width: 400px; /* 最大寬度 */
    max-height: 80%; /* 最大高度為視窗高度的 80% */
    overflow-y: auto; /* 垂直滾動 */
    z-index: 1100; /* 必須高於 modal-overlay 的內容 */
  }
  
}

/* 手機橫式 */
@media screen and (min-width: 481px) and (max-width: 767px) {

  .main-content {
    flex-direction: row; /* 橫屏時回到橫向排列 */
    align-items: flex-start; /* 頂對齊 */
  }

    .image-container {
    width: auto;
    min-width: 300px; /* 設置最小寬度 */
  }

  .order-image {
    width: 100%; /* 讓圖片適應容器 */
    height: auto; /* 保持比例 */
  }

    .workflow-button {
    grid-template-columns: repeat(5, 1fr); /* 每排 6 個按鈕 */
    color: black !important; /* 固定文字顏色為黑色 */
    background-color: #ffffcc; /* 保持背景顏色一致 */
  }

    th, td {
      color: black !important; /* 表格文字顏色固定為黑色 */
    }

    h1, h2, label, p {
      color: black !important; /* 所有標題、標籤和段落文字固定為黑色 */
    }

    .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 添加陰影 */
    width: 50%; /* 調整為屏幕寬度的 90% */
    max-width: 400px; /* 最大寬度 */
    max-height: 80%; /* 最大高度為視窗高度的 80% */
    overflow-y: auto; /* 垂直滾動 */
    z-index: 1100; /* 必須高於 modal-overlay 的內容 */
  }
}

</style>