import { createRouter, createWebHashHistory } from "vue-router";
import LoginPage from "@/components/LoginPage.vue";
import UserDashboard from "@/components/UserDashboard.vue"; // Dashboard 頁面
import EditPage from "@/components/EditPage.vue"; // 資料修改頁面

const routes = [
  {
    path: "/",
    name: "Login",
    component: LoginPage,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: UserDashboard,
  },
  {
    path: "/edit/:id", // 使用 id 作為動態路由參數
    name: "EditPage",
    component: EditPage,
    props: (route) => ({
      id: route.params.id,          // 將 id 作為 props
      image: route.query.image,     // 將 image 從 query 傳遞為 props
    }),
  }
];

const router = createRouter({
  history: createWebHashHistory(), // 改為 hash 模式
  routes,
});

export default router;
